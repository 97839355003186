import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import BlogPostListItem from '../components/BlogPostListItem';
import Pagination from '../components/Pagination';
import ByTag from '../components/ByTag';
import ByCategory from '../components/ByCategory';
import HtmlContent from '../components/HtmlContent';

const BlogListTemplate = ({ data, pageContext }) => {
  const { currentPage, numPages, basePath, tag: currentTag, category: currentCategory } = pageContext;
  const { edges: posts } = data.allMarkdownRemark || { edges: [] };
  const { group: tags } = data.tags || { group: [] };
  const { group: categories } = data.categories || { group: [] };

  return (
    <Layout>
      <div className="blog-list">
        <div className="container g-pt-80 g-pb-20">
          <div className="row justify-content-between">

            <div className="col-lg-9 g-mb-80">
              <div className="g-pr-20--lg">
                {posts.map(({ node: post }, index) => {
                  const {
                    frontmatter: { title, image, dateString },
                    fields: { slug, teaser },
                    excerpt,
                  } = post;
                  return (
                    <BlogPostListItem
                      key={post.id}
                      slug={slug}
                      title={title}
                      teaser={teaser && <HtmlContent content={teaser} />}
                      image={image}
                      excerpt={excerpt}
                      dateString={dateString}
                      index={index}
                    />
                  );
                })}

                <Pagination currentPage={currentPage} numPages={numPages} basePath={basePath} />
              </div>
            </div>

            <div className="col-lg-3 g-brd-left--lg g-brd-gray-light-v4 g-mb-80">
              <div className="g-pl-20--lg">
                <div
                  id="stickyblock-start"
                  className="js-sticky-block g-sticky-block--lg g-pt-50"
                  data-start-point="#stickyblock-start" data-end-point="#stickyblock-end" data-has-sticky-header="true"
                >

                  <ByCategory categories={categories} currentCategory={currentCategory} />

                  <hr className="g-brd-gray-light-v4 g-my-50" />

                  <ByTag tags={tags} currentTag={currentTag} />
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </Layout>
  );
};

BlogListTemplate.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.arrayOf(PropTypes.shape({
        node: PropTypes.object, /* TODO: post shape */
      })),
    }),
    tags: PropTypes.shape({
      group: PropTypes.arrayOf(PropTypes.shape({
        fieldValue: PropTypes.string,
        totalCount: PropTypes.number,
      })),
    }),
    categories: PropTypes.shape({
      group: PropTypes.arrayOf(PropTypes.shape({
        fieldValue: PropTypes.string,
        totalCount: PropTypes.number,
      })),
    }),
  }).isRequired,
  pageContext: PropTypes.shape({
    tag: PropTypes.string,
    category: PropTypes.string,
  }),
};

export default BlogListTemplate;

export const pageQuery = graphql`
  query ListQuery($filter: filterMarkdownRemark, $limit: Int!, $skip: Int!) {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] },
      filter: $filter
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          excerpt(pruneLength: 400)
          id
          fields {
            slug
            teaser
          }
          ...BlogPostFrontmatter
        }
      }
    }
    tags: allMarkdownRemark {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
    categories: allMarkdownRemark {
      group(field: frontmatter___category) {
        fieldValue
        totalCount
      }
    }
  }
`;
