import React from 'react';
import PropTypes from 'prop-types';
import { kebabCase } from 'lodash';
import { Link } from 'gatsby';

// Keep this in sync with the Netlify CMS UI selections as configured in /static/admin/config.yml
// TODO: Temp, derive from actual data once we have a post in each category
const CATEGORIES = ['General', 'Clinicians', 'Product News', 'Company News'];

const ByCategory = ({ categories, currentCategory }) => (
  <div className="g-mb-50">
    <h3 className="h5 g-font-weight-600 mb-4">Categories</h3>
    <ul className="list-unstyled g-font-size-13 mb-0">
      {
        ['ALL', ...CATEGORIES].map((category) => {
          const categoryPath = category === 'ALL' ? '/blog/' : `/blog/category/${kebabCase(category)}/`;
          const count = category === 'ALL' ?
            categories.reduce((c, { totalCount }) => c + totalCount, 0) || 0
            : (categories.find((group) => group.fieldValue === category) || {}).totalCount || 0;
          const isSelectedCategory = category === currentCategory;
          return (
            <li key={categoryPath}>
              <Link
                to={categoryPath}
                activeClassName="disabled"
                className={`d-block u-link-v5 g-px-20 g-py-8 ${isSelectedCategory ? 'active g-color-black g-bg-gray-light-v5 g-font-weight-600 g-rounded-50' : ''}`}
              >
                <i className="mr-2 fa fa-angle-right" /> {category} ({count})
              </Link>
            </li>
          );
        })
      }
    </ul>
  </div>
);

ByCategory.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.shape({
    fieldValue: PropTypes.string,
    totalCount: PropTypes.number,
  })).isRequired,
  currentCategory: PropTypes.string,
};

export default ByCategory;
