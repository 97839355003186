import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

// FUTURE TODO: Omit page numbers too far form current when they can't fit on-screen (but always show first and last)
// (currently on mobile screens we can fit 6 page links)
// perhaps like done here: https://github.com/swashata/swas.io/blob/master/src/templates/BlogPage.jsx

// Currently hardcodes previous = "Newer" and next = "Older"
const Pagination = ({ currentPage, numPages, basePath }) => {
  const isFirst = currentPage === 1;
  const isLast = currentPage === numPages;
  const prevPage = currentPage - 1 === 1 ? basePath : `${basePath}${(currentPage - 1).toString()}`;
  const nextPage = `${basePath}${(currentPage + 1).toString()}`;

  return (
    <nav id="stickyblock-end" className="text-center" aria-label="Page Navigation">
      {numPages > 1 &&
        <ul className="list-inline">
          {!isFirst &&
          <li className="list-inline-item float-left g-hidden-xs-down">
            <Link
              to={prevPage}
              rel="prev"
              className="u-pagination-v1__item u-pagination-v1-4 g-brd-gray-light-v3 g-brd-primary--hover g-rounded-50 g-pa-7-16"
              aria-label="Newer"
            >
              <span aria-hidden="true"> <i className="fa fa-angle-left g-mr-5" /> Newer </span>
              <span className="sr-only">Newer</span>
            </Link>
          </li>
          }

          {
            Array.from({ length: numPages }, (_, i) => (
              <li key={`pagination-number${i + 1}`} className="list-inline-item">
                <Link
                  to={`${basePath}${i === 0 ? '' : i + 1}`}
                  activeClassName="active disabled u-pagination-v1-4--active"
                  className="u-pagination-v1__item u-pagination-v1-4 g-rounded-50 g-pa-7-14"
                >
                  {i + 1}
                </Link>
              </li>
            ))
          }

          {!isLast &&
          <li className="list-inline-item float-right g-hidden-xs-down">
            <Link
              to={nextPage}
              rel="next"
              className="u-pagination-v1__item u-pagination-v1-4 g-brd-gray-light-v3 g-brd-primary--hover g-rounded-50 g-pa-7-16"
              ria-label="Older"
            >
              <span aria-hidden="true"> Older <i className="fa fa-angle-right g-ml-5" /></span>
              <span className="sr-only">Older</span>
            </Link>
          </li>
          }
        </ul>
      }
    </nav>
  );
};

Pagination.propTypes = {
  currentPage: PropTypes.number,
  numPages: PropTypes.number,
  basePath: PropTypes.string,
};

export default Pagination;
