import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'gatsby';

const BlogPostListItem = ({ slug, title, dateString, teaser, excerpt, image, index }) => {
  let style;
  if (image && image.match(/ucarecdn/)) {
    // For the above-fold (first) image, stretch a tiny version of this image.
    // It's only visible on very slow connections. It would be better to base64-encode and embed but this is hard to do with remote urls.
    const backgroundImage = !index && `url(${image}-/format/auto/-/scale_crop/44x23/center/-/quality/lightest/)`;
    image += '-/format/auto/-/scale_crop/882x453/center/'; // progressive/yes doesn't seem to help even when JPEG is served on very slow connections
    style = { paddingBottom: `${(453 / 882) * 100}%`, backgroundImage };
  }
  return (
    <article className="g-mb-90">
      {image &&
        <Link to={slug}>
          <div className="g-mb-25 g-rounded-5 g-pos-rel g-bg-size-cover" style={style}>
            <img className={`img-fluid w-100 g-rounded-5 ${style ? 'g-pos-abs' : ''}`} src={image} alt="" />
          </div>
        </Link>
      }
      <div className="px-4">
        <ul className="d-flex justify-content-start align-items-end list-inline g-color-gray-dark-v5 g-font-size-13 g-mb-25">
          <li className="list-inline-item mr-5 g-mt-minus-45" style={{ display: 'none' }}>
            <img className="g-width-40 g-height-40 g-brd-around g-brd-2 g-brd-white rounded-circle mb-2" src="" alt="" />
            <h4 className="h6 g-font-weight-600 g-font-size-13 mb-0"> <a className="g-color-gray-dark-v4" href="#!">Pregnancy Coach Staff</a> </h4>
          </li>
          <li className="list-inline-item">
            <span className="g-font-size-12">{dateString}</span>
          </li>
        </ul>
        <h2 className="h5 g-font-weight-600">
          <Link to={slug}>{title}</Link>
        </h2>
        <div className="g-color-gray-dark-v4">{teaser || excerpt}</div>
        <Link to={slug} className="g-font-weight-700 g-font-size-13">read more</Link>
      </div>
    </article>
  );
};

BlogPostListItem.propTypes = {
  slug: PropTypes.string.isRequired,
  title: PropTypes.string,
  dateString: PropTypes.string,
  teaser: PropTypes.node,
  image: PropTypes.string,
  excerpt: PropTypes.string,
  index: PropTypes.number,
};

export default BlogPostListItem;
