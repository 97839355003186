import React from 'react';
import PropTypes from 'prop-types';
import { kebabCase } from 'lodash';
import { Link } from 'gatsby';

const ByTag = ({ tags, currentTag }) => (
  <div className="g-mb-40">
    <h3 className="h5 g-font-weight-600 mb-4">Tags</h3>
    <ul className="u-list-inline mb-0">
      {
        tags.map(({ fieldValue: tag, totalCount: count }) => {
          const tagPath = `/blog/tag/${kebabCase(tag)}/`;
          const isSelectedTag = tag === currentTag;
          return (
            <li className="list-inline-item g-mb-10" key={tagPath}>
              <Link
                to={tagPath}
                activeClassName="disabled"
                className={`u-tags-v1 g-font-size-12 g-rounded-50 g-py-4 g-px-15 ${isSelectedTag ? 'active g-bg-primary g-color-white g-font-weight-600' : 'g-color-gray-dark-v4 g-color-white--hover g-bg-gray-light-v5 g-bg-primary--hover'}`}
              >
                {tag} ({count})
              </Link>
            </li>
          );
        })
      }
    </ul>
  </div>
);

ByTag.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.shape({
    fieldValue: PropTypes.string,
    totalCount: PropTypes.number,
  })).isRequired,
  currentTag: PropTypes.string,
};

export default ByTag;
